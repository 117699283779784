.App {
  text-align: center;
  box-sizing: border-box;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Hide the scrollbar for all elements */
body::-webkit-scrollbar {
  width: 0.5em; /* Adjust the width as needed */
}

/* Optional: Customize the scrollbar track */
body::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Change to your desired background color */
}

/* Optional: Customize the scrollbar thumb */
body::-webkit-scrollbar-thumb {
  background-color: black; /* Change to your desired thumb color */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
